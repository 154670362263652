import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTestResult } from "../../../redux/actions/studentActions";

import Header from "../Header";
import Sidebar from "../Sidebar";
import Body from "./Body";
import { mainPageBody, mainPageContent } from "../../../utils/styles";

const TestResult = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  useEffect(() => {
    try {
      dispatch(
        getTestResult(
          user?.result?.department,
          user?.result?.year,
          user?.result?.section
        )
      );
    } catch (error) {
      console.error("An error occurred while fetching test results:", error);
      alert("An error occurred while fetching test results: " + error.message);
    }
  }, [dispatch]);
  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default TestResult;
