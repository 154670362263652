import React, { useState, useEffect } from "react";
import HomeIcon from "@mui/icons-material/Home";
import Calendar from "react-calendar";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BoyIcon from "@mui/icons-material/Boy";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import Notice from "../notices/Notice";
import ReplyIcon from "@mui/icons-material/Reply";
import ShowNotice from "../notices/ShowNotice";
import { pageBody, pageBodyContentContainer } from "../../utils/styles";
import axios from "axios";

const Body = () => {
  const [value, onChange] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [openNotice, setOpenNotice] = useState({});
  const notices = useSelector((state) => state.admin.notices);
  const [lectures, setLectures] = useState([]);
  const [totalTests, setTotalTests] = useState([]);
  const [totalSubjectsForDepartment, setTotalSubjectsForDepartment] = useState(
    []
  );
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchLectures = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/student/attendance`,
        {
          department: `MBA`,
          year: `2023`,
          section: `1`,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        }
      );
      console.log("student attendance", response.data);
      setLectures(response.data.response.result);
    } catch (error) {
      console.error("Error fetching lectures:", error);
    }
  };

  const fetchTotalTests = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/faculty/gettotaltests`,
        {
          department: `MBA`,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      console.log("total tests", response.data.response.totalTests);
      setTotalTests(response.data.response.totalTests);
    } catch (error) {
      console.error("Error fetching total tests:", error);
    }
  };

  const fetchTotalSubjectsForDepartment = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/faculty/getallsubjectsfordepartment`,
        {
          department: `MBA`,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      console.log("totalsubjects", response.data.response.subjects);
      setTotalSubjectsForDepartment(response.data.response.subjects);
    } catch (error) {
      console.error("Error fetching total tests:", error);
    }
  };

  useEffect(() => {
    fetchLectures();
    fetchTotalTests();
    fetchTotalSubjectsForDepartment();
  }, []);
  let totalClassesTaken = 0;

  lectures.forEach((lecture) => {
    totalClassesTaken += lecture.total;
  });

  useEffect(() => {
    fetchLectures();
    fetchTotalTests();
  }, []);
  return (
    <div className={pageBody}>
      <div className="flex text-gray-400 items-center space-x-2">
        <HomeIcon />
        <h1>Dashboard</h1>
      </div>
      <div className={pageBodyContentContainer}>
        <div className="bg-white h-fit pb-5 rounded-xl shadow-lg grid md:grid-cols-4 justify-between px-8 items-center md:space-x-4 space-y-4 md:space-y-0">
          <div className="flex items-center space-x-4 bg-blue-500 max-w-72 text-white rounded-lg p-4">
            <EngineeringIcon
              className="rounded-full bg-white text-blue-500 p-2"
              sx={{ fontSize: 40 }}
            />
            <div className="flex flex-col items-center">
              <h1 className="text-lg">Courses</h1>
              <h2 className="text-2xl font-bold">
                {totalSubjectsForDepartment.length}
              </h2>
            </div>
          </div>
          <div className="flex items-center space-x-4 bg-red-500 max-w-72 text-white rounded-lg p-4">
            <BoyIcon
              className="rounded-full bg-white text-red-500 p-2"
              sx={{ fontSize: 40 }}
            />
            <div className="flex flex-col items-center">
              <h1 className="text-lg">Tests</h1>
              <h2 className="text-2xl font-bold">{totalTests}</h2>
            </div>
          </div>
          <div className="flex items-center space-x-4 bg-yellow-500 max-w-72 text-white rounded-lg p-4">
            <SupervisorAccountIcon
              className="rounded-full bg-white text-yellow-500 p-2"
              sx={{ fontSize: 40 }}
            />
            <div className="flex flex-col items-center">
              <h1 className="text-lg">Attendance</h1>
              <h2 className="text-2xl font-bold">{totalClassesTaken}</h2>
            </div>
          </div>
          <div className="flex items-center space-x-4 bg-green-500 max-w-72 text-white rounded-lg p-4">
            <MenuBookIcon
              className="rounded-full bg-white text-green-500 p-2"
              sx={{ fontSize: 40 }}
            />
            <div className="flex flex-col items-center">
              <h1 className="text-lg">department</h1>
              <h2 className="text-2xl font-bold">{user.result.department}</h2>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
          <div className="bg-white h-[20rem] p-6 rounded-lg shadow-md flex justify-center items-center">
            <Calendar onChange={onChange} value={value} />
          </div>

          <div className="bg-white h-[20rem] rounded-lg shadow-md flex flex-col p-6">
            <div className="flex items-center justify-between">
              {open && (
                <ReplyIcon
                  onClick={() => setOpen(false)}
                  className="cursor-pointer"
                />
              )}
              <h1 className="font-bold text-lg">Notices</h1>
            </div>
            <div className="overflow-y-auto mt-4 space-y-3">
              {!open ? (
                notices?.map((notice, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      setOpen(true);
                      setOpenNotice(notice);
                    }}
                    className="cursor-pointer bg-gray-100 p-2 rounded-md shadow-sm"
                  >
                    <Notice idx={idx} notice={notice} notFor="student" />
                  </div>
                ))
              ) : (
                <ShowNotice notice={openNotice} />
              )}
            </div>
          </div>

          <div className="bg-white h-[20rem] rounded-lg shadow-md p-6">
            <h1 className="font-bold text-lg mb-4">Total Lectures Taken</h1>
            <table className="w-full text-left table-auto border-collapse">
              <thead>
                <tr>
                  <th className="border-b py-2 text-sm">Subject</th>
                  <th className="border-b py-2 text-sm">Lectures</th>
                </tr>
              </thead>
              <tbody>
                {lectures.map((lectures, idx) => (
                  <tr key={idx}>
                    <td className="border-b py-2 text-sm">
                      {lectures.subjectName}
                    </td>
                    <td className="border-b py-2 text-sm">{lectures.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
