import React, { useEffect, useState } from "react";
import BoyIcon from "@mui/icons-material/Boy";
import { useDispatch, useSelector } from "react-redux";
import { getStudent, uploadMark } from "../../../redux/actions/facultyActions";
import { MenuItem, Select } from "@mui/material";
import Spinner from "../../../utils/Spinner";
import * as classes from "../../../utils/styles";
import { MARKS_UPLOADED, SET_ERRORS } from "../../../redux/actionTypes";
import { getTest } from "../../../redux/actions/facultyActions";
const Body = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state);
  const tests = store.faculty.tests;
  console.log(tests);
  const [marks, setMarks] = useState([]);
  const [totmarks, setTotMarks] = useState(null);
  const [value, setValue] = useState({
    department: "",
    year: "",
    section: "",
    test: "",
  });
  // console.log(value.test);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    if (Object.keys(store.errors).length !== 0) {
      //setError(store.errors);
      // alert("An error occurred. Please check your input." + `${store.errors}`);
      // setLoading(false);
      setValue({ department: "", year: "", section: "", test: "" });
    }
  }, [store.errors]);

  const handleInputChange = (value, _id) => {
    const newMarks = [...marks];
    let index = newMarks.findIndex((m) => m._id === _id);
    if (index === -1) {
      newMarks.push({ _id, value });
    } else {
      newMarks[index].value = value;
    }
    setMarks(newMarks);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSearch(true);
    setLoading(true);
    setError({});
    try {
      await dispatch(getStudent(value)); 
    } catch (error) {
      alert("Failed to fetch students. Please try again." + error.message);
      console.error("Error fetching students:", error); 
      
    }finally{
      setLoading(false);
    }
  };

  const students = useSelector((state) => state.admin.students);

  const uploadMarks = async(e)=> {
    setError({});
    try {
      await dispatch(
        uploadMark(marks, value.department, value.section, value.year, value.test)
      ); 
      alert("Marks uploaded successfully."); 
    } catch (error) {
      alert("Failed to upload marks. Please try again." + error.message);
      console.error("Error uploading marks:", error); 
      
    }finally{
      setLoading(false);
    }
  };

    const handleFileChange = ({ base64, type }) => {
      if (type.startsWith("image/")) {
        setValue({ ...value, avatar: base64 });
      } else {
        alert("Please select an image file.");
        window.location.reload();
      }
    };

  useEffect(() => {
    if (students?.length !== 0) setLoading(false);
  }, [students]);

  useEffect(() => {
    dispatch({ type: SET_ERRORS, payload: {} });
    setValue({ ...value, department: user.result.department });
  }, []);

  useEffect(() => {
    if (store.errors || store.faculty.marksUploaded) {
      setLoading(false);
      if (store.faculty.marksUploaded) {
        setValue({ department: "", year: "", test: "", section: "" });
        setSearch(false);
        dispatch({ type: SET_ERRORS, payload: {} });
        dispatch({ type: MARKS_UPLOADED, payload: false });
      }
    } else {
      setLoading(true);
    }
  }, [store.errors, store.faculty.marksUploaded]);

  useEffect(() => {
    if (value.year !== "" && value.section !== "") {
      dispatch(getTest(value));
    }
  }, [value.year, value.section]);

  return (
    <div className="flex-[0.8] mt-3">
      <div className="space-y-5">
        <div className="flex text-gray-400 items-center space-x-2">
          <BoyIcon />
          <h1>All Students</h1>
        </div>
        <div className=" mr-10 bg-white grid md:grid-cols-4 rounded-xl pt-6 pl-6 h-[29.5rem]">
          <form
            className="flex flex-col space-y-2 col-span-1"
            onSubmit={handleSubmit}
          >
            <label htmlFor="year">Year</label>
            <Select
              required
              displayEmpty
              sx={{ height: 36, width: 224 }}
              inputProps={{ "aria-label": "Without label" }}
              value={value.year}
              onChange={(e) => setValue({ ...value, year: e.target.value })}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2025">2025</MenuItem>
            </Select>
            <label htmlFor="section">Semester</label>
            <Select
              required
              displayEmpty
              sx={{ height: 36, width: 224 }}
              inputProps={{ "aria-label": "Without label" }}
              value={value.section}
              onChange={(e) => setValue({ ...value, section: e.target.value })}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
            </Select>
            <label htmlFor="year">Test</label>
            <Select
              required
              displayEmpty
              sx={{ height: 36, width: 224 }}
              inputProps={{ "aria-label": "Without label" }}
              value={value.test}
              onChange={(e) => {
                const selectedTest = tests.find(
                  (test) => test.test === e.target.value
                );
                setValue({ ...value, test: e.target.value });
                setTotMarks(selectedTest.totalMarks);
              }}
            >
              <MenuItem value="">None</MenuItem>
              {tests?.map((test, idx) => (
                <MenuItem value={test.test} key={idx}>
                  {test.test}
                </MenuItem>
              ))}
            </Select>
            <button
              className={`${classes.adminFormSubmitButton} w-56`}
              type="submit"
            >
              Search
            </button>
          </form>
          <div className={classes.tableDataContainer}>
            <div className={classes.loadingAndError}>
              {loading && (
                <Spinner
                  message="Loading"
                  height={50}
                  width={150}
                  color="#111111"
                  messageColor="blue"
                />
              )}
              {(error.noStudentError || error.backendError) && (
                <p className="text-red-500 text-2xl font-bold">
                  {error.noStudentError || error.backendError}
                </p>
              )}
            </div>
            <form onSubmit={uploadMarks}>
              {search &&
                !loading &&
                Object.keys(error).length === 0 &&
                students?.length !== 0 && (
                  <div className={`${classes.adminData} h-fit `}>
                    <div className="grid grid-cols-8">
                      <h1 className={`col-span-1 ${classes.adminDataHeading}`}>
                        Sr no.
                      </h1>
                      <h1 className={`col-span-2 ${classes.adminDataHeading}`}>
                        Name
                      </h1>
                      <h1 className={`col-span-2 ${classes.adminDataHeading}`}>
                        Username
                      </h1>

                      <h1 className={`col-span-1 ${classes.adminDataHeading}`}>
                        Semester
                      </h1>
                      <h1 className={`col-span-2 ${classes.adminDataHeading}`}>
                        Marks
                      </h1>
                    </div>
                    {students?.map((stu, idx) => (
                      <div
                        key={idx}
                        className={`${classes.adminDataBody} grid-cols-8`}
                      >
                        <h1
                          className={`col-span-1 ${classes.adminDataBodyFields}`}
                        >
                          {idx + 1}
                        </h1>
                        <h1
                          className={`col-span-2 ${classes.adminDataBodyFields}`}
                        >
                          {stu.name}
                        </h1>
                        <h1
                          className={`col-span-2 ${classes.adminDataBodyFields}`}
                        >
                          {stu.username}
                        </h1>

                        <h1
                          className={`col-span-1 ${classes.adminDataBodyFields}`}
                        >
                          {stu.section}
                        </h1>
                        <input
                          required
                          onChange={(e) =>
                            handleInputChange(e.target.value, stu._id)
                          }
                          value={stu.marks}
                          className="col-span-2 border-2 w-24 px-2 h-8"
                          type="number"
                          min="0"
                          max={totmarks}
                        />
                      </div>
                    ))}
                  </div>
                )}
              {search && Object.keys(error).length === 0 && (
                <div className="">
                  <button
                    type="submit"
                    className={`${classes.adminFormSubmitButton} bg-blue-500 mt-5 ml-[22rem]`}
                  >
                    Upload
                  </button>
                </div>
              )}
              {(error.examError || error.backendError) && (
                <p className="text-red-500 text-2xl font-bold ml-32">
                  {error.examError || error.backendError}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
