import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

export default function BarChart({ attendanceData }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const calculateOverallPercentage = () => {
    if (!Array.isArray(attendanceData) || attendanceData.length === 0) return 0;

    let totalAttended = 0;
    let totalClasses = 0;

    attendanceData.forEach(item => {
      const attended = parseFloat(item.attended, 10);
      const total = parseFloat(item.total, 10);
      if (!isNaN(attended) && !isNaN(total)) {
        totalAttended += attended;
        totalClasses += total;
      }
    });

    return totalClasses > 0 ? ((totalAttended / totalClasses) * 100).toFixed(2) : 0;
  };

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    const labels = attendanceData?.map((item) => item.subjectName);
    const data = attendanceData?.map((item) => parseFloat(item.percentage));

    // Function to generate dynamic colors
    const generateColors = (numColors) => {
      const colors = [];
      for (let i = 0; i < numColors; i++) {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        colors.push(`rgba(${r}, ${g}, ${b}, 0.2)`);
      }
      return colors;
    };

    const barColors = generateColors(data?.length);

    chartInstance.current = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Attendance Percentage",
            data: data,
            backgroundColor: barColors,
          },
        ],
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [attendanceData]);

  const overallPercentage = calculateOverallPercentage();

  return (
    <div>
      <h2 style={{ fontWeight: 'bold', color: 'black' }}>
        Overall Attendance Percentage: {overallPercentage}%
      </h2>
      <canvas ref={chartRef} />
    </div>
  );
}
