import React, { useEffect } from "react";
import Body from "./Body";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { useDispatch } from "react-redux";
import { getAllDepartment } from "../../../../redux/actions/adminActions";
import { mainPageBody, mainPageContent } from "../../../../utils/styles";

const Update = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllDepartment());
      } catch (err) {
        alert(`An error occurred while fetching departments: ${err.message}`);
        console.log(err);
      }
    };
    fetchData();
  }, [dispatch]);
  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default Update;
