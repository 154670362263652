import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllDepartment } from "../../../redux/actions/adminActions";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Body from "./Body";
import { mainPageBody, mainPageContent } from "../../../utils/styles";

const GetSubject = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllDepartment());
      } catch (error) {
        alert("Error in fetching data" + error.message);
        console.log(error);
      }
    };
    fetchData();
  }, [dispatch]);
  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default GetSubject;
