import React, { useEffect } from "react";
import Body from "./Body";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getPendingFeeRequests } from "../../../redux/actions/accountActions";
import { mainPageBody, mainPageContent } from "../../../utils/styles";

const Pending = () => {
  const store = useSelector((state) => state);

  const dispatch = useDispatch();
  useEffect(() => {
    
    const fetchPendingFeeRequests = async () => {
      try {
        await dispatch(getPendingFeeRequests("unpaid"));
      } catch (error) {
        console.error("Error fetching pending fee requests:", error);
        alert(`Error occured: ${error.message}`); 
      }
    };

    fetchPendingFeeRequests();
  }, [dispatch, store.account.feeAdded]);
  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default Pending;
