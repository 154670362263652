import React, { useEffect, useState } from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { useDispatch, useSelector } from "react-redux";
import { getOldFeeRequests } from "../../../redux/actions/accountActions";
import * as classes from "../../../utils/styles";
import { SET_ERRORS } from "../../../redux/actionTypes";
import Card from "./Card";
const Body = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state);

  useEffect(() => {
    if (Object.keys(store.errors).length !== 0) {
      setError(store.errors);
      setLoading(false);
     
    }
  }, [store.errors]);

  const handleSubmit = async () => {
    setLoading(true);
    setError({});
    try {
      await dispatch(getOldFeeRequests("paid"));
    } catch (error) {
      alert("An error occurred while fetching fee requests." + error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch({ type: SET_ERRORS, payload: {} });
    handleSubmit();
  }, []);

  const newFees = useSelector((state) => state.account.oldFees);
  console.log(newFees);

  useEffect(() => {
    if (newFees?.length !== 0) {
      setLoading(false);
    }
  }, [newFees]);

  return (
    <div className="flex-[0.8] mt-3">
      <div className="space-y-5">
        <div className="flex text-gray-400 items-center space-x-2">
          <EngineeringIcon />
          <h1>All Settled Fees</h1>
        </div>
        <div className=" mr-10 bg-white grid grid-cols-3 rounded-xl pt-6 pl-6 h-[29.5rem] overflow-auto">
          {newFees.map((fee) => {
            return <Card fee={fee} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Body;

/* {!loading &&
              Object.keys(error).length === 0 &&
              newFees?.length !== 0 && (
                <div className={classes.adminData}>
                  <div className="grid grid-cols-12">
                    <h1 className={`${classes.adminDataHeading} col-span-1 `}>
                      Sr no.
                    </h1>
                    <h1 className={`${classes.adminDataHeading} col-span-3 `}>
                      Name
                    </h1>
                    <h1 className={`${classes.adminDataHeading} col-span-2 `}>
                      Username
                    </h1>
                    <h1 className={`${classes.adminDataHeading} col-span-3 `}>
                      Email
                    </h1>
                    <h1 className={`${classes.adminDataHeading} col-span-3 `}>
                      Fees
                    </h1>
                    <h1 className={`${classes.adminDataHeading} col-span-3 `}>
                      Proof
                    </h1>
                    <h1 className={`${classes.adminDataHeading} col-span-3 `}>
                      Remarks
                    </h1>
                  </div>
                  {newFees?.map((fac, idx) => (
                    <div
                      key={idx}
                      className={`${classes.adminDataBody} grid-cols-12`}
                    >
                      <h1
                        className={`${classes.adminDataBodyFields} font-bold border-0 col-span-1`}
                      >
                        {idx + 1}
                      </h1>
                      <h1
                        className={`col-span-3 ${classes.adminDataBodyFields}`}
                      >
                        {fac.name}
                      </h1>
                      <h1
                        className={`col-span-2 ${classes.adminDataBodyFields} `}
                      >
                        {fac.username}
                      </h1>
                      <h1
                        className={`col-span-3 ${classes.adminDataBodyFields}`}
                      >
                        {fac.email}
                      </h1>
                    </div>
                  ))}
                </div>
              )} */
